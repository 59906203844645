<template>
  <div class="container-page">
    <div class="create-projects">
      <!-- title -->
      <h2 class="create-projects__title title-inner">
        {{
          projectId == 0
            ? 'Матрица проектов – Создание нового проекта МПК'
            : 'Матрица проектов – Редактирование карточки проекта МПК'
        }}
      </h2>

      <div>
        <div class="create-projects__row create-projects__row--start">
          <div class="w-100 required create-projects__wrapper-col--mt-25">
            <p class="create-projects__label required">Название проекта</p>
            <ComponentInput required v-model="item.name" mod="redesigned" placeholder="Введите название" />
          </div>

          <div class="w-100">
            <p class="create-projects__label required">Страна реализации инвестиционного проекта</p>
            <SelectMultiComponentCheckbox
              placeholder="Выберите страну"
              :options="countries"
              v-model="projectListCountries"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              required
            />
          </div>
        </div>
        <div class="create-projects__row create-projects__row--start">
          <div class="w-100">
            <p class="create-projects__label required">Cтрана-партнер</p>
            <SelectSingleComponentCheckbox
              placeholder="Выберите страну"
              :options="countries"
              v-model="item.partnerCountryId"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              required
            />
          </div>
          <div class="w-100">
            <p class="create-projects__label required">Цели проекта</p>
            <SelectMultiComponentCheckbox
              placeholder="Выберите цели проекта"
              :options="purposes"
              v-model="projectPurposes"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              required
            />
          </div>
        </div>
        <div class="create-projects__row create-projects__row--start">
          <div class="w-100 create-projects__wrapper-col--mt-25">
            <p class="create-projects__label required">Статус инвестиционного проекта</p>
            <SelectSingleComponentCheckbox
              placeholder="Выберите статус"
              :options="statuses"
              v-model="item.statusId"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              required
            />
          </div>

          <div class="w-100">
            <p class="create-projects__label required">Дата внесения информации о статусе проекта</p>
            <DateComponent
              v-model="item.dateProjectStatus"
              :clearable="false"
              placeholder="дд.мм.гггг"
              modClass="redesigned"
              dateIcon
            />
          </div>
        </div>

        <div class="create-projects__row">
          <div class="w-100">
            <p class="create-projects__label">Перечень создаваемых объектов</p>
            <ComponentInput v-model="item.createdObjectsList" mod="redesigned" placeholder="Введите название" />
          </div>
          <div class="w-100">
            <p class="create-projects__label">Перечень создаваемых объектов, наименование</p>
            <ComponentInput v-model="item.createdObjectListName" mod="redesigned" placeholder="Введите наименование" />
          </div>
        </div>

        <div class="create-projects__row create-projects__row--start">
          <div class="w-100">
            <p class="create-projects__label">Перечень проекта</p>
            <SelectSingleComponentCheckbox
              placeholder="Выберите перечень проекта"
              :options="getMpkListProjects"
              v-model="item.projectListId"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
            />
          </div>

          <div class="w-100">
            <p class="create-projects__label required">Сфера деятельности</p>
            <SelectSingleComponentCheckbox
              placeholder="Выберите сферу деятельности"
              :options="industries"
              v-model="item.industryId"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              required
            />
          </div>
        </div>
        <div class="w-100 required">
          <div class="checkboxInput">
            <input v-model="item.isCrossBorderProject" type="checkbox" id="isCrossBorder" />
            <label for="isCrossBorder" class="create-projects__label required">Трансграничный проект</label>
          </div>
        </div>
        <hr />
        <h3 class="title-inputBlock create-projects__block-title">Участники</h3>
        <div class="create-projects__participants">
          <div class="create-projects__row create-projects__row--start">
            <div class="w-100">
              <p class="create-projects__label required">Организация, реализующая проект/SPV</p>
              <ComponentInput
                v-model="item.organizationImplementingProject"
                required
                mod="redesigned"
                placeholder="Введите организацию"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label required">Инициатор проекта (головная организация)</p>
              <ComponentInput
                v-model="item.initiator"
                required
                mod="redesigned"
                placeholder="Введите инициатора проекта"
              />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="create-projects__wrapper-col--50">
              <p class="create-projects__label">ИНН организации, реализующей проект</p>
              <ComponentInput v-model="item.innOrganization" mod="redesigned" placeholder="Введите ИНН" />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="create-projects__wrapper-describe">
              <p class="create-projects__label required">Описание проекта</p>
              <ComponentInput
                v-model="item.description"
                type="textarea"
                modClass="redesigned"
                required
                placeholder="Введите описание проекта"
              />
            </div>
          </div>
        </div>
        <hr />
        <h3 class="title-inputBlock create-projects__block-title">Период реализации</h3>
        <div class="create-projects__row create-projects__row--start">
          <div class="w-100">
            <p class="create-projects__label required">Плановый год начала реализации инвестиционной стадии проекта</p>
            <DateComponent
              v-model="plannedYearStartInvStage"
              :clearable="false"
              placeholder="гггг"
              modClass="redesigned"
              minimumViewDate="year"
              dateIcon
              required
            />
          </div>

          <div class="w-100">
            <p class="create-projects__label required">
              Плановый год окончания реализации инвестиционной стадии проекта
            </p>
            <DateComponent
              v-model="plannedYearEndInvStage"
              :clearable="false"
              placeholder="гггг"
              modClass="redesigned"
              minimumViewDate="year"
              dateIcon
              required
            />
          </div>
        </div>
        <hr />
        <h3 class="title-inputBlock create-projects__block-title">Ход реализации проекта</h3>
        <h4 class="title-inputBlock create-projects__block-subtitle">Параметры</h4>
        <div class="create-projects__row create-projects__row--start">
          <div class="w-100">
            <p class="create-projects__label required">Общий объем инвестиций, млрд руб. (не распределено)</p>
            <ComponentInput
              required
              v-model="item.totalInvestmentMlrdRubNotDistributed"
              mod="redesigned"
              placeholder="Введите объем инвестиций"
              type="number"
              min="0"
            />
          </div>
          <div class="w-100 total-investment-rub-col--mt-25">
            <p class="create-projects__label required">Общий объем инвестиций, млрд руб.</p>
            <ComponentInput
              v-model="item.totalInvestmentMlrdRub"
              mod="redesigned"
              placeholder="Введите объем инвестиций"
              required
              type="number"
              min="0"
            />
          </div>
        </div>
        <div class="create-projects__row">
          <div class="create-projects__wrapper-col--50">
            <p class="create-projects__label required">Общий объем инвестиций, тыс. долларов</p>
            <ComponentInput
              required
              v-model="item.totalInvestmentThousDol"
              mod="redesigned"
              placeholder="Введите объем инвестиций"
              type="number"
              min="0"
            />
          </div>
        </div>
        <div class="create-projects__row create-projects__params">
          <div class="w-100">
            <p class="create-projects__label">Конечный бенефициар инвестпроекта</p>
            <ComponentInput
              v-model="item.finalBeneficiary"
              mod="redesigned"
              placeholder="Введите конечного бенефициара"
            />
          </div>
          <div class="w-100">
            <p class="create-projects__label">ФОИВ-куратор</p>
            <SelectSingleComponentCheckbox
              placeholder="Выберите куратора"
              :options="foivs"
              v-model="item.foivCuratorId"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
            />
          </div>
        </div>

        <h4 class="title-inputBlock create-projects__block-subtitle">Дополнительные параметры</h4>
        <div class="create-projects__add-params">
          <div class="create-projects__row create-projects__row--start">
            <div class="w-100">
              <p class="create-projects__label required">Постоянные новые рабочие места, чел.</p>
              <ComponentInput
                v-model="item.constantNewJobs"
                mod="redesigned"
                required
                type="number"
                min="0"
                placeholder="Введите количество рабочих мест"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label">Временные новые рабочие места, чел</p>
              <ComponentInput
                v-model="item.temporaryNewJobs"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Введите количество рабочих мест"
              />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="create-projects__wrapper-col--70">
              <p class="create-projects__label">
                Субъект Российской Федерации, в котором будет реализован инвестиционный проект
              </p>
              <SelectSingleComponentCheckbox
                placeholder="Выберите регион"
                :options="russianRegions"
                :disabled="!isRussia"
                v-model="item.regionOfRussiaId"
                trackBy="text"
                valueProp="id"
                labelOptions="text"
              />
            </div>
          </div>
          <div class="create-projects__row create-projects__OKVED">
            <div class="w-100">
              <p class="create-projects__label">Раздел ОКВЭД</p>
              <ComponentInput v-model="item.nsiOkvedSection" mod="redesigned" placeholder="Введите раздел" />
            </div>
            <div class="create-projects__wrapper-col--50">
              <p class="create-projects__label">Группа ОКВЭД</p>
              <ComponentInput v-model="item.nsiOkvedGroup" mod="redesigned" placeholder="Введите группу" />
            </div>
            <div class="create-projects__wrapper-col--50">
              <p class="create-projects__label">Код ОКВЭД</p>
              <ComponentInput v-model="item.nsiCodeOkved" mod="redesigned" placeholder="Введите код" />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="create-projects__wrapper-col--70">
              <p class="create-projects__label required">Курирующий заместитель Министра</p>

              <ComponentInput
                v-model="item.supervisingMinister"
                mod="redesigned"
                required
                placeholder="Введите имя заместителя"
              />
            </div>
          </div>
          <div class="create-projects__row create-projects__row--start">
            <div class="w-100">
              <p class="create-projects__label required">Ответственный департамент МЭР</p>
              <SelectSingleComponentCheckbox
                placeholder="Выберите департамент"
                v-model="item.departmentId"
                :options="getMpkProjectDepartments"
                trackBy="text"
                valueProp="id"
                labelOptions="text"
                required
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label required">Степень участия МЭР в проекте</p>
              <SelectSingleComponentCheckbox
                placeholder="Выберите степень участия"
                v-model="item.minRoleId"
                :options="getMpkProjectMinRoles"
                trackBy="text"
                valueProp="id"
                labelOptions="text"
                required
              />
            </div>
          </div>

          <div class="create-projects__row create-projects__row--start">
            <div class="w-100">
              <p class="create-projects__label required">Иная степень участия МЭР в проекте</p>
              <ComponentInput
                v-model="item.minRoleOther"
                mod="redesigned"
                required
                placeholder="Введите иную степень участия"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label required">Меры поддержки</p>
              <SelectSingleComponentCheckbox
                placeholder="Выберите меру поддержки"
                v-model="item.supportMeasureId"
                :options="getMpkProjectSupportMeasures"
                trackBy="text"
                valueProp="id"
                labelOptions="text"
                required
              />
            </div>
          </div>

          <div class="create-projects__row">
            <div class="w-100">
              <p class="create-projects__label">Плановый год начала эксплуатационной стадии проекта</p>
              <DateComponent
                v-model="plannedYearStartOpStage"
                :clearable="false"
                placeholder="гггг"
                modClass="redesigned"
                minimumViewDate="year"
                dateIcon
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label">Плановый год выхода на проектную мощность</p>
              <DateComponent
                v-model="plannedYearDesignCapacity"
                :clearable="false"
                placeholder="гггг"
                modClass="redesigned"
                minimumViewDate="year"
                dateIcon
              />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="create-projects__wrapper-col--50">
              <p class="create-projects__label">Сайт компании</p>
              <ComponentInput v-model="item.webSite" mod="redesigned" placeholder="Введите сайт" />
            </div>
          </div>

          <div class="create-projects__row">
            <div class="w-100">
              <p class="create-projects__label">Чистая приведенная стоимость (NPV), млрд руб.</p>
              <ComponentInput
                v-model="item.npvValue"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Введите NPV"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label">Внутренняя норма окупаемость (IRR), %</p>
              <ComponentInput
                v-model="item.irrValue"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Введите IRR"
              />
            </div>
          </div>

          <div class="create-projects__row">
            <div class="create-projects__wrapper-col--50">
              <p class="create-projects__label">Срок реализации инвестиционной фазы, лет</p>
              <ComponentInput
                v-model="item.implementationPeriodInvestmentPhase"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Введите срок реализации"
              />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="create-projects__wrapper-col--70">
              <p class="create-projects__label required">
                Наименование меры поддержки, в рамках которой ведется учет инвестиционных проектов
              </p>
              <SelectSingleComponentCheckbox
                placeholder="Введите наименование меры поддержки"
                v-model="item.projectCategoryId"
                :options="getMpkProjectCategories"
                trackBy="text"
                valueProp="id"
                labelOptions="text"
                required
              />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="create-projects__wrapper-col--70">
              <p class="create-projects__label required">
                Наименование иной меры поддержки, в рамках которой ведется учет инвестиционных проектов
              </p>
              <ComponentInput
                v-model="item.otherProjectCateogory"
                mod="redesigned"
                required
                placeholder="Введите наименование иной меры поддержки"
              />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="create-projects__wrapper-col--50">
              <p class="create-projects__label">Курс ЦБ на дату заключения контракта</p>
              <ComponentInput
                v-model="item.centralBankExchangeRate"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Введите курс ЦБ"
              />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="checkboxInput">
              <input type="checkbox" v-model="item.notSpecifiedFundingFlag" id="isFinanceStrAct" />
              <label class="create-projects__label" for="isFinanceStrAct">Могу указать структуру финансирования</label>
            </div>
          </div>
          <div class="create-projects__row create-projects__row--start">
            <div class="w-100 budget-funding-col--mt-25">
              <p class="create-projects__label" :class="{ required: item.notSpecifiedFundingFlag }">
                Бюджетное финансирование, млрд. руб.
              </p>
              <ComponentInput
                placeholder="Укажите финансирование"
                v-model="item.budgetFunding"
                mod="redesigned"
                :required="item.notSpecifiedFundingFlag"
                type="number"
                min="0"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label" :class="{ required: item.notSpecifiedFundingFlag }">
                Финансирование из федерального бюджета (в т.ч. в рамках мер поддержки), млрд, руб.
              </p>
              <ComponentInput
                placeholder="Укажите финансирование"
                v-model="item.federalBudget"
                mod="redesigned"
                :required="item.notSpecifiedFundingFlag"
                type="number"
                min="0"
              />
            </div>
          </div>

          <div class="create-projects__row create-projects__row--start">
            <div class="w-100">
              <p class="create-projects__label" :class="{ required: item.notSpecifiedFundingFlag }">
                Финансирование из региональных бюджетов (в т.ч. в рамках мер поддержки), млрд, руб.
              </p>
              <ComponentInput
                placeholder="Укажите финансирование"
                v-model="item.regionalBudget"
                mod="redesigned"
                :required="item.notSpecifiedFundingFlag"
                type="number"
                min="0"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label">
                Финансирование из муниципальных бюджетов (в т.ч. в рамках мер поддержки), млрд руб.
              </p>
              <ComponentInput
                v-model="item.municipalBudget"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Укажите финансирование"
              />
            </div>
          </div>

          <div class="create-projects__row create-projects__row--start">
            <div class="w-100">
              <p class="create-projects__label" :class="{ required: item.notSpecifiedFundingFlag }">
                Собственные средства, млрд. руб.
              </p>
              <ComponentInput
                v-model="item.ownBudget"
                mod="redesigned"
                :required="item.notSpecifiedFundingFlag"
                type="number"
                min="0"
                placeholder="Укажите собственные средства"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label">Привлеченные средства, млрд. руб.</p>
              <ComponentInput
                v-model="item.raisedBudget"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Укажите привлеченные средства"
              />
            </div>
          </div>

          <div class="create-projects__row create-projects__row--start">
            <div class="w-100 not-specified-funding-col--mt-25">
              <p class="create-projects__label" :class="{ required: item.notSpecifiedFundingFlag }">
                Банковские кредиты (в т.ч. в рамках мер поддержки), млрд. руб.
              </p>
              <ComponentInput
                v-model="item.bankLoans"
                mod="redesigned"
                :required="item.notSpecifiedFundingFlag"
                type="number"
                min="0"
                placeholder="Укажите банковские кредиты"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label" :class="{ required: item.notSpecifiedFundingFlag }">
                Иные заемные и привлеченные средства (в т.ч. в рамках мер поддержки), млрд. руб.
              </p>
              <ComponentInput
                v-model="item.otherBorrowedFunds"
                mod="redesigned"
                :required="item.notSpecifiedFundingFlag"
                type="number"
                min="0"
                placeholder="Укажите привлеченные средства"
              />
            </div>
          </div>

          <!-- region new fields -->

          <div class="create-projects__row">
            <div class="w-100">
              <p class="create-projects__label">Бюджетное финансирование, тыс. долларов</p>
              <ComponentInput
                v-model="item.budgetFundingDollars"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Укажите финансирование"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label">
                Финансирование из федерального бюджета (в т.ч. в рамках мер поддержки), тыс. долларов
              </p>
              <ComponentInput
                v-model="item.federalBudgetDollars"
                mod="redesigned"
                type="number"
                placeholder="Укажите финансирование"
              />
            </div>
          </div>

          <div class="create-projects__row">
            <div class="w-100">
              <p class="create-projects__label">
                Финансирование из региональных бюджетов (в т.ч. в рамках мер поддержки), тыс. долларов
              </p>
              <ComponentInput
                v-model="item.regionalBudgetDollars"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Укажите финансирование"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label">
                Финансирование из муниципальных бюджетов (в т.ч. в рамках мер поддержки), тыс. долларов
              </p>
              <ComponentInput
                v-model="item.municipalBudgetDollars"
                mod="redesigned"
                type="number"
                placeholder="Укажите финансирование"
              />
            </div>
          </div>

          <div class="create-projects__row">
            <div class="w-100">
              <p class="create-projects__label">Собственные средства, тыс. долларов</p>
              <ComponentInput
                v-model="item.ownBudgetDollars"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Укажите собственные средства"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label">Привлеченные средства, тыс. долларов</p>
              <ComponentInput
                v-model="item.raisedBudgetDollars"
                mod="redesigned"
                type="number"
                placeholder="Укажите привлеченные средства"
              />
            </div>
          </div>

          <div class="create-projects__row">
            <div class="w-100">
              <p class="create-projects__label">Банковские кредиты (в т.ч. в рамках мер поддержки), тыс. долларов</p>
              <ComponentInput
                v-model="item.bankLoansDollars"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Укажите банковские кредиты"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label">
                Иные заёмные и привлечённые средства (в т.ч. в рамках мер поддержки), тыс. долларов
              </p>
              <ComponentInput
                v-model="item.otherBorrowedFundsDollars"
                mod="redesigned"
                type="number"
                placeholder="Укажите привлеченные средства"
              />
            </div>
          </div>

          <!-- region -->
          <div class="create-projects__row">
            <div class="create-projects__wrapper-col--70">
              <p class="create-projects__label">Объем проинвестированных средств, млрд. руб.</p>
              <ComponentInput
                v-model="item.volumeOfInvestedFunds"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Укажите объем проинвестированных средств"
              />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="create-projects__wrapper-col--50">
              <p class="create-projects__label">Дата внесения информации об объеме инвестиций</p>
              <DateComponent
                v-model="item.dateEnteringInfoOnVolumeInvestments"
                :clearable="false"
                placeholder="дд.мм.гггг"
                modClass="redesigned"
                dateIcon
              />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="w-100">
              <p class="create-projects__label">Среднегодовой объем чистой прибыли, млрд. руб</p>
              <ComponentInput
                v-model="item.averageAnnualNetProfit"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Введите объем чистой прибыли"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label">Среднегодовая выручка после выхода на проектную мощность, млрд. руб.</p>
              <ComponentInput
                v-model="item.averageAnnualRevenueAfterReachingDesignCapacity"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Введите среднегодовую выручку"
              />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="w-100">
              <p class="create-projects__label">Налоговые выплаты на инвестиционной стадии, млрд. руб.</p>
              <ComponentInput
                v-model="item.taxPaymentsAtTheInvestmentStage"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Введите налоговые выплаты"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label">Налоговые выплаты на эксплуатационной стадии, млрд. руб.</p>
              <ComponentInput
                v-model="item.taxPaymentsAtTheOperationalStage"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Введите налоговые выплаты"
              />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="create-projects__wrapper-col--70">
              <p class="create-projects__label">
                Среднегодовая сумма налоговых выплат после выхода на проектную мощность, млрд. руб.
              </p>
              <ComponentInput
                v-model="item.averageAnnualAmountOfTaxPaymentsAfterReachingDesignCapacity"
                mod="redesigned"
                type="number"
                min="0"
                placeholder="Введите сумму налоговых выплат"
              />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="create-projects__wrapper-col--70">
              <p class="create-projects__label required">Тип записи</p>
              <SelectSingleComponentCheckbox
                placeholder="Выберите тип записи"
                v-model="item.typeEntryId"
                :options="getMpkProjectTypeEntries"
                trackBy="text"
                valueProp="id"
                labelOptions="text"
                required
              />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="create-projects__wrapper-col--70">
              <p class="create-projects__label">Ссылка на дублирующий проект</p>
              <ComponentInput v-model="item.duplicateProjectLink" mod="redesigned" placeholder="Введите ссылку" />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="w-100">
              <p class="create-projects__label">Ссылка на дублирующий проект, наименование</p>
              <ComponentInput
                v-model="item.duplicateProjectLinkName"
                mod="redesigned"
                placeholder="Введите наименование"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label">Геотип объекта</p>
              <ComponentInput v-model="item.objectGeotype" mod="redesigned" placeholder="Введите геотип объекта" />
            </div>
          </div>
          <div class="create-projects__row">
            <div class="w-100">
              <p class="create-projects__label">Широта объекта в координатах WGS-84</p>
              <ComponentInput v-model="item.objectLatitude" mod="redesigned" placeholder="Введите координаты" />
            </div>
            <div class="w-100">
              <p class="create-projects__label">Долгота объекта в координатах WGS-84</p>
              <ComponentInput v-model="item.objectLongitude" mod="redesigned" placeholder="Введите координаты" />
            </div>
            <div class="w-100">
              <p class="create-projects__label">Набор координат линии объекта в координатах WGS-84</p>
              <ComponentInput v-model="item.lineCoordinates" mod="redesigned" placeholder="Введите координаты" />
            </div>
            <div class="w-100">
              <p class="create-projects__label">Набор координат полигона объекта в координатах WGS-84</p>
              <ComponentInput v-model="item.polygonCoordinates" mod="redesigned" placeholder="Введите координаты" />
            </div>
          </div>
          <div class="create-projects__row create-projects__row--start">
            <div class="w-100">
              <p class="create-projects__label required">Каталог-источник</p>
              <ComponentInput
                v-model="item.catalogNumber"
                mod="redesigned"
                required
                placeholder="Введите каталог-источник"
              />
            </div>
            <div class="w-100">
              <p class="create-projects__label required">Код во внешнем источнике</p>
              <ComponentInput v-model="item.ehdId" mod="redesigned" required placeholder="Введите код" type="number" />
            </div>
          </div>

          <div class="create-projects__row">
            <div class="w-100">
              <p class="create-projects__label required">Дата подписания</p>
              <DateComponent
                v-model="item.signatureDate"
                :clearable="false"
                placeholder="дд.мм.гггг"
                modClass="redesigned"
                dateIcon
              />
            </div>

            <div class="w-100">
              <p class="create-projects__label required">Дата создания</p>
              <DateComponent
                v-model="item.createDate"
                :clearable="false"
                placeholder="дд.мм.гггг"
                modClass="redesigned"
                dateIcon
              />
            </div>
          </div>
        </div>
        <hr />
        <div class="create-projects__row create-projects__row--start">
          <div class="w-100">
            <p class="create-projects__label required">Проблема</p>
            <ComponentInput
              v-model="item.problems"
              type="textarea"
              mod="redesigned"
              required
              placeholder="Введите проблему"
            />
          </div>
          <div class="w-100">
            <p class="create-projects__label required">Проблема (комментарий)</p>
            <ComponentInput
              v-model="item.comment"
              type="textarea"
              mod="redesigned"
              required
              placeholder="Введите комментарий"
            />
          </div>
        </div>
        <div class="create-projects__row">
          <div class="create-projects__wrapper-col--50">
            <p class="create-projects__label">Ссылка на портал МПК</p>
            <ComponentInput v-model="item.portalMpkUrl" mod="redesigned" placeholder="Введите ссылку" />
          </div>
        </div>

        <div class="create-projects__row" v-if="isKNR">
          <div class="w-100">
            <p class="create-projects__label">Подробнее</p>
            <ComponentInput v-model="item.details" type="textarea" mod="redesigned" />
          </div>
        </div>
        <div class="create-projects__row" v-if="isKNR">
          <div class="col col-lg-2">
            <p class="create-projects__label">Обсуждение на межгосударственных мероприятиях</p>
          </div>
          <div class="col col-lg-8">
            <div v-if="!item.discussionEvents || item.discussionEvents.length === 0">Мероприятия не указаны</div>
            <div v-else>
              <div v-for="(event, index) in item.discussionEvents" :key="index" class="create-projects__row">
                <div class="col col-lg-2">
                  <DateComponent
                    required
                    v-model="event.eventDate"
                    :clearable="true"
                    placeholder="Дата мероприятия"
                    modClass="redesigned"
                  />
                </div>
                <div class="col col-lg-5">
                  <ComponentInput v-model="event.name" placeholder="Название мероприятия" required />
                </div>
                <div class="col col-lg-4">
                  <FileUpload
                    width="100%"
                    :entity="event"
                    @deleteFile="removeEventFile(index)"
                    required
                    prop-name="file"
                    :entity-index="index"
                    @uploaded="fileEventUploaded"
                  />
                </div>
                <div class="col col-lg-1">
                  <IconComponent @click="deleteEvent(index)" name="delete" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isKNR" class="create-project__row create-project__row--center event-btn">
          <ButtonStock @click="addEvent()" btnType="blue" title="Добавить поле" />
        </div>

        <div class="create-projects__row">
          <div class="checkboxInput">
            <input
              v-model="item.isFoivReviewed"
              type="checkbox"
              :disabled="!item.isReviewFoivAvailable && item.fullRights"
              id="isFoivReviewed"
            />
            <label for="isFoivReviewed" class="create-projects__label">Рассмотрено ФОИВ</label>
          </div>
        </div>
        <div class="create-projects__row">
          <div class="checkboxInput">
            <input
              class="disabled"
              v-model="item.needUpdating"
              type="checkbox"
              :disabled="!item.fullRights && !isCurator"
              id="isNeedUpd"
            />
            <label for="isNeedUpd" class="create-projects__label">Требуется актуализация</label>
          </div>
        </div>

        <div class="create-projects__row">
          <ButtonStock
            title="Отправить уведомление"
            v-on:click="createChat"
            v-if="item.foivCuratorId && !chatExists && item.fullRights"
          />
          <ButtonStock title="Перейти к чату" v-on:click="gotoChat" v-if="item.foivCuratorId && chatExists" />
        </div>

        <div class="create-projects__row">
          <ButtonStock class="btn-save" title="Сохранить" v-on:click="save" />
          <ButtonStock class="btn-cancel" title="Отмена" v-on:click="close" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';
  import moment from 'moment';

  import ComponentInput from '@/common/components/ComponentInput';
  import DateComponent from '@/common/components/dateComponent';
  import FileUpload from '@/common/components/FileUpload';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API_ORG from '@/modules/admin/api/organization';
  import API_COMMISSION from '@/modules/mpk/api/commission';
  import API_NSI from '@/modules/nsi/api/nsi';

  import API from '../api/index';
  import { MPK_PROJECT_ACTIONS_NAME, MPK_PROJECT_GETTERS_NAME } from '../store/name';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    name: 'EditProjectCard',
    components: {
      ButtonStock,
      ComponentInput,
      IconComponent,
      FileUpload,
      DateComponent,
      SelectSingleComponentCheckbox,
      SelectMultiComponentCheckbox,
    },
    data() {
      return {
        projectId: this.$route.params.id,
        item: {},
        statuses: [],
        industries: [],
        countries: [],
        russianRegions: [],
        mpkCommission: [],
        purposes: [],
        participantsDomestic: [],
        foivs: [],
        createInputCount: 1,
        inputsArr: [{ id: 1 }],
        chats: [],
        isCurator: false,
        plannedYearStartInvStage: null,
        plannedYearEndInvStage: null,
        plannedYearStartOpStage: null,
        plannedYearDesignCapacity: null,
        projectPurposes: [],
        projectCountries: [],
      };
    },
    mixins: [security],
    created() {
      this.checkPermissions([Constants.Permissions.MpkProjectEdit, Constants.Permissions.MpkProjectsAccess]);
      this.loadCard();
      this.loadDictionaries();
      this.loadOtherDictionaries();
    },
    computed: {
      ...mapGetters({
        getMpkProjectDepartments: MPK_PROJECT_GETTERS_NAME.getMpkProjectDepartments,
        getMpkProjectMinRoles: MPK_PROJECT_GETTERS_NAME.getMpkProjectMinRoles,
        getMpkListProjects: MPK_PROJECT_GETTERS_NAME.getMpkListProjects,

        getMpkProjectSupportMeasures: MPK_PROJECT_GETTERS_NAME.getMpkProjectSupportMeasures,
        getMpkProjectCategories: MPK_PROJECT_GETTERS_NAME.getMpkProjectCategories,
        getMpkProjectTypeEntries: MPK_PROJECT_GETTERS_NAME.getMpkProjectTypeEntries,
        regionsList: DIRECTIVES_GETTERS_NAME.getRegionsSearchIdList,
      }),
      isKNR() {
        if (this.projectCountries.length === 0) {
          return false;
        }
        return this.projectCountries.includes(Constants.countryKNR);
      },
      isRussia() {
        if (this.projectCountries.length === 0) {
          return false;
        }
        return this.projectCountries.includes(Constants.countryRussiaId);
      },
      chatExists() {
        return this.item.foivCuratorId && this.chats[this.item.foivCuratorId] > 0;
      },
      projectListCountries: {
        get() {
          return this.projectCountries;
        },
        set(value) {
          this.projectCountries = value.map((id) => Number(id));
        },
      },
    },
    methods: {
      ...mapActions({
        getMpkProjectDepartment: MPK_PROJECT_ACTIONS_NAME.getMpkProjectDepartment,
        getMpkProjectMinRole: MPK_PROJECT_ACTIONS_NAME.getMpkProjectMinRole,
        getMpkProjectList: MPK_PROJECT_ACTIONS_NAME.getMpkProjectList,
        getMpkProjectSupportMeasure: MPK_PROJECT_ACTIONS_NAME.getMpkProjectSupportMeasure,
        getMpkProjectCategoryList: MPK_PROJECT_ACTIONS_NAME.getMpkProjectCategoryList,
        getMpkProjectTypeEntry: MPK_PROJECT_ACTIONS_NAME.getMpkProjectTypeEntry,
        getRegionsSearchId: DIRECTIVES_ACTIONS_NAME.getRegionsSearchId,
      }),
      loadCard() {
        if (this.projectId == 0) {
          this.item.fundingAmounts = [];
          this.item.fundingAmounts.push({ year: '', amount: '' });

          // if (this.$route.query && this.$route.query.countryId > 0) {
          //   this.item.countryId = this.$route.query.countryId;
          // }

          this.checkPermission(Constants.Permissions.MpkProjectFoivReview).then((response) => {
            this.item.fullRights = !response.data;
          });
        } else {
          API.find(this.projectId).then((response) => {
            this.item = response.data;
            if (!this.item.canEdit) {
              Constants.alert.fire(
                'Недостаточно прав',
                'У вас недостаточно прав для просмотра данных проекта',
                'error',
              );
              this.$router.push({ name: 'Projects' });
            } else {
              this.item.startMonthYear = moment(this.item.startMonthYear).format('YYYY-MM');
              this.item.endMonthYear = moment(this.item.endMonthYear).format('YYYY-MM');
              this.projectCountries = this.item.projectCountries.map(({ countryId }) => countryId);
              this.projectPurposes = this.item.projectPurposes.map(({ purposeId }) => purposeId);
              this.plannedYearStartInvStage = this.item?.plannedYearStartInvStage;
              this.plannedYearEndInvStage = this.item?.plannedYearEndInvStage;
              this.plannedYearStartOpStage = this.item?.plannedYearStartOpStage;
              this.plannedYearDesignCapacity = this.item?.plannedYearDesignCapacity;

              if (!this.item.fundingAmounts || this.item.fundingAmounts.length === 0) {
                this.item.fundingAmounts = [];
                this.item.fundingAmounts.push({ year: '', amount: '' });
              } else {
                this.item.fundingAmounts = JSON.parse(this.item.fundingAmount);
              }
            }
          });
          API.getChats(this.projectId).then((res) => {
            if (res && res.data) {
              this.chats = res.data;
            }
          });
        }
        let profile = this.$store.state.auth?.oidc?.profile;
        if (profile == null) profile = JSON.parse(localStorage.getItem('userProfileData'));
        this.isCurator = profile?.roleIds.includes(2);
      },
      loadDictionaries() {
        let request = { pageNumber: 1, pageSize: 100000 };
        //страны
        Utils.loadSelectOptions('countryList', this.countries);
        //отрасли
        API_NSI.search('NsiIndustry', request).then((response) => {
          if (response.data) {
            this.industries = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
        //цели
        API_NSI.search('MpkProjectPurpose', request).then((response) => {
          if (response.data) {
            this.purposes = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
        Utils.loadSelectOptions('mpkProjectStatusList', this.statuses);

        request = { pageNumber: 1, pageSize: 0 };
        API_ORG.searchShortFormat(request).then((response) => {
          if (response.data) {
            this.participantsDomestic = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
        request = { pageNumber: 1, pageSize: 0, filter: { typeIds: ['1'] } };
        API_ORG.searchShortFormat(request).then((res) => {
          this.foivs = res.data.items.map((x) => ({ id: x.id, text: x.name }));
        });
        // мпк
        request = { shortFormat: true, hasParent: false };
        API_COMMISSION.search(request).then((response) => {
          if (response.data) {
            this.mpkCommission = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
      },
      async loadOtherDictionaries() {
        // регионы России
        const regResponse = await this.getRegionsSearchId();
        if (regResponse.success) {
          this.russianRegions = this.regionsList.map(({ id, name }) => ({ id, text: name }));
        }

        // ответственный департамент МЭР
        await this.getMpkProjectDepartment({ pageNumber: 1, pageSize: 0 });
        // Степень участия МЭР в проекте
        await this.getMpkProjectMinRole();
        // Перечень проекта
        await this.getMpkProjectList();
        // Меры поддержки
        await this.getMpkProjectSupportMeasure();
        // Наименование меры поддержки, в рамках которой ведется учет инвестиционных проектов
        await this.getMpkProjectCategoryList();
        // Тип записи
        await this.getMpkProjectTypeEntry();
      },
      fileEventUploaded(e) {
        this.item.discussionEvents[e.entityIndex].file = e.data;
        this.item.discussionEvents[e.entityIndex].fileId = e.data ? e.data.id : null;
      },
      removeEventFile(index) {
        this.item.discussionEvents[index].file = {};
        this.item.discussionEvents[index].fileId = null;
      },
      addEvent() {
        if (!this.item.discussionEvents) this.item.discussionEvents = [];
        this.item.discussionEvents.unshift({ id: 0 });
      },
      deleteEvent(index) {
        this.item.discussionEvents.splice(index, 1);
      },
      createChat() {
        if (this.projectId > 0) {
          API.createChat({
            projectId: this.projectId,
            orgId: this.item.foivCuratorId,
          })
            .then((res) => {
              if (res && res.data && res.data.id) {
                this.$router.push({
                  name: 'Messenger',
                  params: { initialDialogId: res.data.id },
                });
              }
            })
            .catch(() => {
              Constants.alert.fire('Создание чата', Constants.commonError, 'error');
            });
        } else {
          Constants.alert.fire('Создание чата', 'Сохраните проект', 'info');
        }
      },
      gotoChat() {
        let chatId = this.chats[this.item.foivCuratorId];
        if (chatId > 0) {
          this.$router.push({
            name: 'Messenger',
            params: { initialDialogId: chatId },
          });
        }
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        this.item.fundingAmount = JSON.stringify(this.item.fundingAmounts);
        if (
          this.item.startMonthYear &&
          this.item.startMonthYear.length > 0 &&
          this.item.startMonthYear != 'Invalid date'
        )
          this.item.startMonthYear = this.item.startMonthYear + '-01';
        else this.item.startMonthYear = null;

        if (this.item.endMonthYear && this.item.endMonthYear.length > 0 && this.item.endMonthYear != 'Invalid date')
          this.item.endMonthYear = this.item.endMonthYear + '-01';
        else this.item.endMonthYear = null;

        this.item.projectCountries = this.projectCountries.map((id) => ({ countryId: Number(id) }));
        this.item.projectPurposes = this.projectPurposes.map((id) => ({ purposeId: Number(id) }));
        this.item.plannedYearStartInvStage = moment(
          this.plannedYearStartInvStage,
          Constants.DATE_FORMATS['YYYY-MM-DD'],
        ).year();
        this.item.plannedYearEndInvStage = moment(
          this.plannedYearEndInvStage,
          Constants.DATE_FORMATS['YYYY-MM-DD'],
        ).year();
        this.item.plannedYearStartOpStage = moment(
          this.plannedYearStartOpStage,
          Constants.DATE_FORMATS['YYYY-MM-DD'],
        ).year();
        this.item.plannedYearDesignCapacity = moment(
          this.plannedYearDesignCapacity,
          Constants.DATE_FORMATS['YYYY-MM-DD'],
        ).year();

        const currentFormatDate = moment(new Date()).format(Constants.DATE_FORMATS['YYYY-MM-DD']);

        this.item.dateProjectStatus = this.item?.dateProjectStatus ?? currentFormatDate;
        this.item.createDate = this.item?.createDate ?? currentFormatDate;
        this.item.signatureDate = this.item?.signatureDate ?? currentFormatDate;

        API.update(this.item)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && response.data.id) {
              //this.$router.push({ name: 'EditProjectCard', params: { id: response.data.id, action: 'edit' } })
              this.$router.push({ name: 'Projects' });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Projects' });
      },
      /** @public */
      createInput() {
        this.createInputCount++;
        this.inputsArr.push({ id: this.createInputCount });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
  };
</script>

<style lang="scss" scoped>
  @import '../styles/EditProjectCard.scss';
</style>

<style lang="scss">
  .create-projects {
    input {
      border-radius: 4px;
    }
    textarea {
      max-height: 120px;
      border-radius: 4px;
    }
  }
</style>
